import "../portfolioPage.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"
import VimeoVideo from "../../../components/portfolio/VimeoVideo"

import LogoTHU from "../../../svg/portfolio/logoTHU.svg"

export default function IndexPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout territory={"art"}>
      <Seo title={"THU 2018"} />
      <PatternLateral territory="art" />
      <h2 className="sectionTitle basic">
        <LogoTHU className="portfolioFileTitle" id="logoTHU" />
      </h2>

      <div className="content portfolioFileContent">
        <div className="filmDetails">
          <span>
            <Trans>Opening Ceremony Video</Trans>
          </span>
          <span className="year">
            <Trans>2018</Trans>
          </span>
        </div>

        <VimeoVideo
          videoId="294302039"
          title={t("Opening Ceremony Video of THU 2018 Edition")}
          autoplay={false}
        >
          <p>
            <Trans>Tales of the Unknown - Opening Ceremony Video</Trans>
          </p>
        </VimeoVideo>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
